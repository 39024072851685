import React from 'react';
import {Helmet} from "react-helmet";

const Uk = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <div className=" bg-gradient-to-r from-sky-500 to-emerald-500 ... text-white text-center py-12">
        <h1 className="text-5xl font-extrabold">Study and Travel in Uk</h1>
        <p className="mt-4 text-lg">Explore your education and travel options with us!</p>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 py-8">
        {/* Study Visa Section */}
        <section id="study-visa" className="my-8">
          <h2 className="text-3xl font-extrabold text-sky-800 mb-6 flex items-center transition-transform transform hover:scale-105">
            <img src="https://img.icons8.com/ios/50/000000/student-center.png" alt="Study Icon" className="w-12 h-12 mr-3" />
            WHY STUDY IN UK?
          </h2>
          <div className="bg-white shadow-xl rounded-lg p-8 mb-6 transition-transform transform hover:scale-105 hover:shadow-2xl">
            <p className="text-gray-700 mb-4">
            The United Kingdom (UK) is a unique country in Northern Europe, made up of England, Scotland, Wales, and Northern Ireland. It's known for its rich history, diverse culture, and strong education system. With London as its capital, the UK is also one of the world’s top financial centers.
            </p>
            <p className="text-gray-700 mb-4">
            Studying in the UK means gaining valuable skills in an engaging and practical way. The focus is on real-world applications, preparing you for your future career. Plus, you’ll enjoy a rich cultural experience that helps you grow as an individual.

            </p>
            <h3 className="text-xl font-semibold text-sky-600 mb-3">EDUCATION IN UK</h3>
            <p className="text-gray-700 mb-4">
            The UK is a topmost choice for students from all around the world. Its educational institutions offer high-quality programs that equip you with both academic knowledge and practical skills. UK degrees are highly respected globally, making it easier for you to find good job opportunities after graduation.
            </p>
            
           
          </div>
          
          {/*Work while study */}
          
          <div className="bg-white shadow-xl rounded-lg p-8 mb-6 transition-transform transform hover:scale-105 hover:shadow-2xl flex items-start">
  <div className="flex-1 pr-4">
    <h3 className="text-xl font-semibold text-sky-600 mb-3">WORK WHILE STUDY</h3>
    <p className="text-gray-700 mb-4">
    As an international student, you can work up to 20 hours a week during the academic term and are permitted to work full-time during holiday breaks
This helps you gain work experience and manage your living costs more easily.

    </p>
   </div>
  {/* <img 
    src="https://ik.imagekit.io/6oa6qejxe/2336.jpg?updatedAt=1725883967496" 
    alt="Vocational School" 
    className="w-1/2 h-22"
  /> */}
</div>

        </section>

        {/* Tourist Visa Section */}
        <section id="tourist-visa" className="my-8">
          <h2 className="text-3xl font-extrabold text-sky-800 mb-6 flex items-center transition-transform transform hover:scale-105">
            <img src="https://img.icons8.com/ios/50/000000/passport.png" alt="Tourist Visa Icon" className="w-12 h-12 mr-3" />
            Uk Tourist Visa - Visitor Visa 
          </h2>
          <div className="bg-white shadow-xl rounded-lg p-8 mb-6 transition-transform transform hover:scale-105 hover:shadow-2xl">
            <p className="text-gray-700 mb-4">
            Are you planning a trip to the UK? Depending on your nationality, you might need a UK tourist visa. This visa allows you to visit the UK for leisure, tourism, or to see friends and family.
            </p>
            <h3 className="text-xl font-semibold text-sky-600 mb-3">Who Needs a UK Tourist Visa?</h3>
            <p className="text-gray-700 mb-4">
              There are two types of applicants:
            </p>
            <ul className="list-disc list-inside mt-4 space-y-2 text-gray-700">
              <li>Non-Visa Nationals: Some citizens don’t need a visa but must still meet certain entry requirements.</li>
              <li>Visa Nationals: Citizens from countries that require a visa must apply for a UK tourist visa before traveling..</li>
            </ul>
            <h3 className="text-xl font-semibold text-sky-600 mb-3 mt-6">Application Process</h3>
            <p className="text-gray-700 mb-4">
              The application process involves checking your eligibility, applying online, paying the visa fee, submitting documents, and waiting for processing.
            </p>
            <ul className="list-disc list-inside mt-4 space-y-2 text-gray-700">
              <li>Check Eligibility: Visit the UK government’s official website to see if you need a visa.
              .</li>
             <li>Complete Your Application: Fill out the online application form honestly and accurately.
             .</li>
             <li>Book Your Appointment: Schedule a visit at your nearest visa application center to submit your documents and biometrics.</li>
            <li>Gather Your Documents: Prepare necessary documents like your passport, photographs, proof of funds, travel itinerary, and accommodation details.</li>
            <li>Pay Fees: Pay the visa application fee and any additional service charges..</li>
            <li>Attend Your Appointment: Go to your appointment for biometric data collection and document submission..</li>
           <li> Wait for Processing :Once you’ve submitted your application, you’ll need to wait for it to be reviewed and processed..</li>
           <li>.Get Your Decision: You will be informed once a decision has been made on your application.</li>
          
           
            </ul>


          </div>

          {/* Visa Requirements */}
          <div className="bg-white shadow-xl rounded-lg p-8 transition-transform transform hover:scale-105 hover:shadow-2xl">
            <h3 className="text-xl font-semibold text-sky-600 mb-3">Visa Requirements</h3>
            <p className="text-gray-700 mb-4">
                <li>Valid Passport: Your passport should be valid for your entire stay in the UK.
                .</li>
             <li>Financial Proof: Show that you can support yourself financially during your trip..</li>
             <li>Accommodation Information: Submit verification of your intended place of residence in the UK..</li>
             <li> Travel Itinerary: Outline your travel plans and how long you plan to stay.
             .</li>
             <li>Purpose of Visit: Clearly explain that you are visiting for tourism or to see family/friends.
             .</li>
             <li>Health Insurance: It’s a good idea to have travel health insurance for your trip. </li>
             
             </p>


  
          </div>
        </section>
        {/* Spouse visa section */}
        <section id="tourist-visa" className="my-8">
          <h2 className="text-3xl font-extrabold text-sky-800 mb-6 flex items-center transition-transform transform hover:scale-105">
            <img src="https://img.icons8.com/ios/50/000000/passport.png" alt="Tourist Visa Icon" className="w-12 h-12 mr-3" />
            Uk Spouse Visa
          </h2>
          <div className="bg-white shadow-xl rounded-lg p-8 mb-6 transition-transform transform hover:scale-105 hover:shadow-2xl">
            <p className="text-gray-700 mb-4">
           
            </p>
            <h3 className="text-xl font-semibold text-sky-600 mb-3">Who can apply for UK Spouse Visa?</h3>
            <p className="text-gray-700 mb-4">
            To successfully apply for a Spouse Visa, you need to meet these criteria:
            </p>
            <ul className="list-disc list-inside mt-4 space-y-2 text-gray-700">
              <li> Both partners must be over 18.
              ;</li>
              <li>You need to have met face-to-face and be legally married. ;</li>
              <li>Your sponsoring partner should have an income of at least £18,600 per year or enough savings.
              ;</li>
              <li>You need to have suitable accommodation for both partners and any dependents..</li>
            </ul>
            <h3 className="text-xl font-semibold text-sky-600 mb-3 mt-6">Requirement</h3>
            <p className="text-gray-700 mb-4">
            To successfully apply for a Spouse Visa, you need to meet these criteria:
            </p>
            <ul className="list-disc list-inside mt-4 space-y-2 text-gray-700">
              <li>You and your spouse must be 18 years old or over..</li>
               <li>You must have met each other and be legally married – this is to prevent arranged marriages.</li>
             <li>You must have enough money to support yourselves (and any dependents) without claiming public funds.</li>
           <li>Your sponsoring partner must earn more than £18,600 per year or have enough savings to be able to sponsor you. The minimum financial requirement is higher if they are also sponsoring dependent children (see our section on Children’s Visas below).</li>
           <li> You must have suitable accommodation for you, your spouse and any dependants.</li>
            </ul>




          </div>

        </section>
      </div>
      <Helmet>
                <meta charSet="utf-8" />
                <title>uk visa consultants in chandigarh</title>
                <meta name="description" content="Looking for UK visa consultants in Chandigarh? My Visa Point provides expert guidance and support for all UK visa applications. Start your journey today!



" />
                <link rel="canonical" href="https://myvisapoint.com/uk" />
            </Helmet>
    </div>
  );
};

export default Uk;
