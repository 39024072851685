import React from "react";
import { Helmet } from "react-helmet";

const TouristVisa = () => {
  return (
    <section className="bg-gray-100 py-16 px-8">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-sky-600">Tourist Visa</h1>
      </div>

      {/* First Section: Image on Left, Content on Right */}
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center mb-16">
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <img
            src="https://ik.imagekit.io/6oa6qejxe/yannis-papanastasopoulos-whLWBRF1S2Y-unsplash.jpg?updatedAt=1725535559687"
            alt="Travel"
            className="w-full h-auto object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500"
          />
        </div>
        <div className="lg:w-1/2 lg:pl-8">
          {/* <p className="text-lg text-gray-700 mb-6">
          Are you dreaming of visiting new places and experiencing diverse cultures? My Visa Point, one of the best visa consultants in Chandigarh, makes it easier for you to get your tourist visa. Whether you're planning a quick getaway or an extended vacation, we ensure smooth and hassle-free visa processing.
          </p> */}
          <p className="text-lg text-gray-700 mb-6">
            Exploring the world should be exciting, not stressful. At
            MYVISAPOINT, we’re dedicated to making your travel dreams a reality
            with our seamless and reliable tourist visa services. Whether you're
            planning a short vacation, visiting family and friends, or attending
            an event abroad, our team is here to guide you through the process.
          </p>
        </div>
      </div>

      {/* Second Section: Content on Left, Image on Right */}
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-start lg:space-x-8 mb-16">
        <div className="lg:w-1/2 space-y-8 mb-8 lg:mb-0">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h6 className="text-2xl font-semibold text-sky-500 mb-4">
              Why You Need a Tourist Visa
            </h6>
            <p className="text-gray-700 mb-4">
              A tourist visa is essential for most international travel. It
              grants you permission to enter and stay in a country for a
              specific period, usually for leisure, tourism, or short-term
              visits. Each country has different requirements and regulations,
              which is why having a trusted visa partner like us ensures that
              the process is hassle-free.
            </p>
          </div>

          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h6 className="text-2xl font-semibold text-sky-500 mb-4">
              Our Services Include:
            </h6>
            <ul className="list-disc list-inside text-gray-700 space-y-4">
              <li>
                <strong>Application Preparation:</strong> Assistance in filling
                out the visa forms correctly and gathering the required
                documents.
              </li>
              <li>
                <strong>Document Review:</strong> We ensure all your documents
                meet the specific requirements to avoid delays or rejections.
              </li>
              <li>
                <strong>Visa Submission:</strong> We handle the submission
                process for you, ensuring everything is in order before it's
                sent to the embassy or consulate.
              </li>
              <li>
                <strong>Interview Assistance:</strong> If required, we help
                prepare you for any interviews, providing tips and guidance to
                boost your chances of success.
              </li>
              <li>
                <strong>Visa Follow-up:</strong> We monitor the status of your
                application and keep you updated on its progress.
              </li>
            </ul>
          </div>
        </div>

        <div className="lg:w-1/2">
          <img
            src="https://ik.imagekit.io/6oa6qejxe/ilnur-kalimullin-ao7E5wZ77ds-unsplash.jpg?updatedAt=1725535605074"
            alt="Adventure"
            className="w-full h-auto max-h-80 object-cover mb-10 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500"
          />
          <img
            src="https://ik.imagekit.io/6oa6qejxe/ilnur-kalimullin-ao7E5wZ77ds-unsplash.jpg?updatedAt=1725535605074"
            alt="Adventure"
            className="w-full h-auto max-h-80 object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500"
          />
        </div>
      </div>

      {/* Countries Offering Tourist Visas */}
      <div className="max-w-6xl mx-auto bg-white p-8 rounded-lg shadow-lg">
  <h6 className="text-2xl font-semibold text-sky-500 mb-4">
    Countries Offering Tourist Visas
  </h6>
  <p className="text-lg text-gray-700">
    Several countries offer tourist visas, allowing travelers to explore
    their beautiful landscapes and cultures. Some popular destinations
    include:
  </p>
  <div className="flex flex-wrap justify-center items-center gap-4 mt-4">
    <img src="https://ik.imagekit.io/tdlebsr5e/25791319_7148300.jpg?updatedAt=1731582811030" alt="USA Flag" className="w-16 h-10 rounded shadow" />
    <img src="https://ik.imagekit.io/tdlebsr5e/2922500_27114%20(1).jpg?updatedAt=1731582605311" alt="Canada Flag" className="w-16 h-10 rounded shadow" />
    <img src="https://ik.imagekit.io/6oa6qejxe/221.jpg?updatedAt=1726218539528" alt="UK Flag" className="w-16 h-10 rounded shadow" />
    <img src="https://ik.imagekit.io/6oa6qejxe/184.jpg?updatedAt=1726223811493" alt="Australia Flag" className="w-16 h-10 rounded shadow" />
    <img src="https://ik.imagekit.io/6oa6qejxe/3049.jpg?updatedAt=1726218301375" alt="New Zealand Flag" className="w-16 h-10 rounded shadow" />
    <img src="https://ik.imagekit.io/6oa6qejxe/3061.jpg?updatedAt=1726218395399" alt="France Flag" className="w-16 h-10 rounded shadow" />
    <img src="https://ik.imagekit.io/6oa6qejxe/2922296_27018.jpg?updatedAt=1726219087066" alt="Germany Flag" className="w-16 h-10 rounded shadow" />
    <img src="https://ik.imagekit.io/6oa6qejxe/235.jpg?updatedAt=1726218790466" alt="Italy Flag" className="w-16 h-10 rounded shadow" />
    <img src="https://ik.imagekit.io/tdlebsr5e/141.jpg?updatedAt=1731583027188" alt="Japan Flag" className="w-16 h-10 rounded shadow" />
    <img src="https://ik.imagekit.io/tdlebsr5e/24633.jpg?updatedAt=1731580993745" alt="Brazil Flag" className="w-16 h-10 rounded shadow" />
  </div>
  <p className="text-lg text-gray-700 mt-4">
    Whether you're planning to visit the <strong>USA</strong> to see the
    Grand Canyon, explore the historical sites of <strong>Italy</strong>,
    or relax on the beaches of <strong>Australia</strong>, obtaining a
    tourist visa is your gateway to international adventures.
  </p>
</div>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Tourist Visa Services in Chandigarh | My Visa Point</title>
        <meta
          name="description"
          content=" Looking for a tourist visa? My Visa Point in Chandigarh offers expert assistance for hassle-free applications. Plan your dream vacation with us today!

"
        />
        <link rel="canonical" href="https://myvisapoint.com/tourist-visa" />
      </Helmet>
    </section>
  );
};

export default TouristVisa;
