import React from 'react';
import {Helmet} from "react-helmet";

const SuperVisa = () => {
  return (
    
    <section className="bg-gray-100 py-16 px-8">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-sky-600">Super Visa</h1>
      </div>

      {/* First Section: Image on Left, Content on Right */}
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center mb-16">
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <img
            src="https://ik.imagekit.io/6oa6qejxe/2149205286.jpg?updatedAt=1725540062974"
            alt="Couple"
            className="w-full h-auto object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500"
          />
        </div>
        <div className="lg:w-1/2 lg:pl-8">
          <p className="text-lg text-gray-700 mb-6">
           
          At MyVisa Point, we understand how important family connections are, especially when it comes to bringing parents and grandparents to visit you in countries like canada for an extended period. The Super Visa allows parents and grandparents of Canadian citizens and permanent residents to stay in Canada for up to two years at a time without the need to renew their status. Our team is here to help you navigate the Super Visa application process.
          </p>
          {/* <p className="text-lg text-gray-700 mb-6">
           Additionally,the Super Visa can be applied for by individuals from any country, provided they meet the eligibility requirements. This means that the Super Visa is open to applicants worldwide.
          </p> */}
        </div>
      </div>

      {/* Second Section: Content on Left, Image on Right */}
      <div className="max-w-6xl mx-auto grid grid-cols-1 lg:grid-cols-3 gap-12 items-start mb-16">
  {/* Text Sections */}
  <div className="lg:col-span-2 space-y-8">
    {/* Benefits Section */}
    <div className="bg-white p-8 rounded-lg shadow-lg">
      <h6 className="text-2xl font-semibold text-sky-500 mb-4">Benefits of the Super Visa</h6>
      <ul className="list-disc list-inside text-gray-700 space-y-4">
        <li>Longer Stay: Parents and grandparents can remain in Canada for up to two years without having to extend their status.</li>
        <li>Multiple Entries: The Super Visa allows multiple entries to Canada for up to 10 years, making it easier for families to stay connected.</li>
        <li>Quick Processing: Super Visa applications are often processed faster than regular visitor visas, allowing for quicker reunification with loved ones.</li>
      </ul>
    </div>

    {/* Services Section */}
    <div className="bg-white p-8 rounded-lg shadow-lg">
      <h6 className="text-2xl font-semibold text-sky-500 mb-4">Our Super Visa Services</h6>
      <ul className="list-disc list-inside text-gray-700 space-y-4">
        <li>Eligibility Assessment: We conduct a thorough assessment to ensure you meet the eligibility criteria for the Super Visa.</li>
        <li>Document Preparation: Our team assists in preparing and reviewing all necessary documents, including the invitation letter, proof of funds, and insurance documents.</li>
        <li>Insurance Guidance: We help you find suitable medical insurance that meets the requirements for the Super Visa, ensuring your application is compliant.</li>
        <li>Application Submission: We handle the submission of your application, ensuring all documents are in order and deadlines are met.</li>
        <li>Follow-up: We monitor the status of your application and keep you updated throughout the process, ensuring you’re informed every step of the way.</li>
      </ul>
    </div>
  </div>

  {/* Image Section */}
  <div className="space-y-8">
    <img
      src="https://ik.imagekit.io/6oa6qejxe/2148619919.jpg?updatedAt=1725540094303"
      alt="Couple"
      className="w-full h-auto object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500"
    />
<div className="w-full h-auto object-cover flex flex-col rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500 hidden sm:block gap-8">
  <img
    src="https://th.bing.com/th/id/OIP.lBvU8HhkM6xJFdX6w0ikeQHaE7?rs=1&pid=ImgDetMain"  
    alt="Family"
    className="w-full h-auto"
    loading="lazy"
  />
  <img
    src="https://mlrblpfsgxr9.i.optimole.com/cb:UF5I.42b5a/w:auto/h:auto/q:mauto/ig:avif/f:best/https://www.visitorguard.com/storage/U.S.-Spouse-Visa-Everything-You-Need-to-Know.webp"  
    alt="Family"
    className="w-full h-auto"
    loading="lazy"
  />
</div>


  </div>
</div>

      <Helmet>
                <meta charSet="utf-8" />
                <title>Super Visa Services in Chandigarh | My Visa Point</title>
                <meta name="description" content=" Explore our Super Visa services in Chandigarh. Get expert assistance with applications and requirements to reunite with family in Canada,USA,UK,Australia,New Zealand,Schengen. Contact us today!
" />
                <link rel="canonical" href="https://myvisapoint.com/super-visa" />
            </Helmet>
    </section>

  );
};

export default SuperVisa;
