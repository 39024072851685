import React from "react";
import { Helmet } from "react-helmet";

const VisaCounselling = () => {
  return (
    <section className="bg-gray-100 py-16 px-8">
      {/* First Section: Image on Left, Content on Right */}
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center mb-16">
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <img
            src="https://ik.imagekit.io/6oa6qejxe/family-therapy-psychologist-office.jpg?updatedAt=1726566624736"
            alt="Top-Up Degree"
            className="w-full h-auto object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500"
          />
        </div>
        <div className="lg:w-1/2 lg:pl-8">
          <h1 className="text-4xl font-bold text-sky-600 mb-4">
            What is Visa Counseling?
          </h1>
          <p className="text-lg text-gray-700 mb-6">
            At My Visa Point, we understand that your journey abroad begins with
            the right guidance. As one of the best visa consultants in
            Chandigarh, we are committed to providing expert visa counseling
            services tailored to your specific needs, whether you're applying
            for a study visa, work visa, or exploring new opportunities abroad.
          </p>
          <p className="text-lg text-gray-700 mb-6">
            Our team of experienced immigration consultants simplifies the visa
            process, ensuring that every step is handled with professionalism
            and care. From determining your eligibility to assisting with
            appeals and reapplications, we are here to make your visa
            application experience smooth and stress-free.
          </p>
          {/* <p className="text-lg text-gray-700 mb-6">
          Visa counseling involves guidance and support in preparing and submitting visa applications. It helps applicants understand the requirements and processes associated with obtaining a visa for various purposes, such as travel, work, study, or immigration. 
          </p> */}
          {/* <p className="text-lg text-gray-700">
            Countries like <strong>UK</strong>, <strong>New Zealand</strong>, and <strong>Australia</strong> offer Top-Up Degrees, allowing students to quickly advance their education and gain internationally recognized qualifications.
          </p> */}
        </div>
      </div>

      {/* Second Section: Content on Left, Image on Right */}
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center">
        <div className="lg:w-1/2 lg:pr-8">
          <div className="bg-white p-8 rounded-lg shadow-lg mb-8 lg:mb-0">
            <h6 className="text-2xl font-semibold text-sky-500 mb-4">
              Services Offered by Our Visa Counselors
            </h6>
            <ul className="list-disc list-inside space-y-2 text-gray-700">
              <li>
                Determine Eligibility: Our counselors verify your background,
                travel purpose, and qualifications to determine if you meet the
                visa requirements..
              </li>
              <li>
                Document Review:We review all necessary documents, ensuring they
                are correctly formatted and meet the standards set by the
                relevant authorities.
              </li>
              <li>
                Submission Guidance: We provide detailed instructions on how to
                submit your visa application, whether online or in person,
                ensuring no detail is overlooked..
              </li>
              <li>
                Mock Interviews: For applicants who require an interview, we
                conduct mock sessions to help you prepare with confidence..
              </li>
              <li>
                Track Application Status: Our counselors track the status of
                your visa application, keeping you updated on any developments.
              </li>
              <li>
                Appeals and Reapplications: If your application is denied, we
                offer expert advice on how to appeal or reapply for your visa. .
              </li>
            </ul>
          </div>
        </div>
        <div className="lg:w-1/2">
          <img
            src="https://ik.imagekit.io/6oa6qejxe/team-working-together-project.jpg?updatedAt=1726566634057"
            alt="Top-Up Degree"
            className="w-full h-auto object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500"
          />
        </div>
      </div>
      {/* <div className="lg:w-1/2 lg:pl-8">
      <p className="text-lg text-gray-700">
      My Visa Point is more than just a consultancy; we are your trusted partner in making your dreams come true. With a reputation as the best immigration consultants in Chandigarh, we’re here to ensure you have everything in place to secure your visa effortlessly.
          </p> 

      </div> */}
      <div className="bg-white max-w-6xl p-9 mt-8 mx-auto rounded-lg shadow-lg transition-transform duration-500 hover:shadow-xl">
        <p className="text-lg text-gray-700 text-justify">
          As a trusted study visa consultant in Chandigarh, we strive to ensure
          that your aspirations are achieved with minimal hurdles. Let My Visa
          Point be your partner in navigating the complex visa process, giving
          you the best chance for success in your international journey.
        </p>
      </div>

      <Helmet>
        <meta charSet="utf-8" />
        <title>best visa consultants in chandigarh</title>
        <meta
          name="description"
          content="Get expert visa counseling from the best consultants in Chandigarh. My Visa Point offers personalized guidance for all your visa application needs. Contact us!


"
        />
        <link rel="canonical" href="https://myvisapoint.com/visa-Counselling" />
      </Helmet>
    </section>
  );
};

export default VisaCounselling;
