import React from "react";
import { Helmet } from "react-helmet";

const IeltsPte = () => {
  return (
    <section className="bg-gray-100 py-16 px-8">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h5 className="text-4xl font-bold text-sky-600">IELTS/PTE</h5>
      </div>

      {/* First Section: Image on Left, Content on Right */}
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center mb-16">
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <img
            src="https://ik.imagekit.io/6oa6qejxe/2147844692.jpg?updatedAt=1726566862697"
            alt="Travel"
            className="w-full h-auto object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500"
          />
        </div>
        <div className="lg:w-1/2 lg:pl-8">
          <p className="text-lg text-gray-700 mb-6">
            Achieving your dreams of studying, working, or migrating abroad
            starts with a strong foundation in English proficiency. At MyVisa
            Point, we offer comprehensive coaching for both IELTS (International
            English Language Testing System) and PTE (Pearson Test of English)
            to help you excel in these globally recognized exams. Our tailored
            coaching programs are designed to meet your specific needs, ensuring
            you are fully prepared for test day.
          </p>
          {/* <p className="text-lg text-gray-700 mb-6">
           At My Visa Point, we understand the importance of language proficiency tests like the IELTS (International English Language Testing System) and PTE (Pearson Test of English). As a trusted name among best study visa consultants in Chandigarh, we provide comprehensive preparation services to help you achieve the scores you need for study or immigration purposes.
          </p> */}
          {/* <p className="text-lg text-gray-700 mb-6">
          PTE is an English language proficiency test designed to assess the ability to use English in academic and professional contexts.
          </p> */}
        </div>
      </div>

      {/* Second Section: Content on Left, Image on Right */}
      <div className="container mx-auto ">
        {/* Section 1 */}
        <div className="flex flex-col lg:flex-row gap-8 justify-center mt-10 items-center lg:items-stretch">
          <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg w-full lg:w-1/2">
            <h6 className="text-xl md:text-2xl font-semibold text-sky-500 mb-4">
              Why Choose MyVisa Point for IELTS & PTE Coaching?
            </h6>
            <p className="text-gray-700 mb-4">
              Expert Trainers: Our certified and experienced trainers are
              well-versed in the IELTS and PTE formats, helping you grasp
              essential strategies for scoring high.
            </p>
            <p className="text-gray-700 mb-4">
              Personalized Study Plans: We understand that each student is
              unique, so we design customized learning plans based on your
              strengths, weaknesses, and goals.
            </p>
            <p className="text-gray-700 mb-4">
              Proven Success: With a track record of helping students achieve
              their desired scores, we focus on both improving language skills
              and test-taking techniques.
            </p>
            <p className="text-gray-700 mb-4">
              Flexible Schedules: We offer flexible class timings, including
              weekend and evening batches, to fit your busy schedule.
            </p>
            <p className="text-gray-700 mb-4">
              Comprehensive Study Material: Access our in-depth study guides,
              practice tests, and mock exams that mimic real test conditions,
              ensuring you're fully prepared.
            </p>
            <p className="text-gray-700 mb-4">
              Small Batch Sizes: Our small class sizes ensure personalized
              attention, enabling you to clarify doubts and progress faster.
            </p>
          </div>
          <img
            src="https://ik.imagekit.io/6oa6qejxe/2148854879.jpg?updatedAt=1726567402863"
            alt="Adventure"
            className="w-full lg:w-1/2 h-auto rounded-lg shadow-lg object-cover transform hover:scale-105 transition-transform duration-500"
          />
        </div>

        {/* Section 2 */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-10">
          {/* IELTS Coaching Program */}
          <div className="bg-white  p-4 md:p-8 rounded-lg shadow-lg">
            <h6 className="text-xl md:text-2xl font-semibold text-sky-500 mb-4">
              Our IELTS Coaching Program
            </h6>
            <p className="text-gray-700 mb-4">
              The IELTS exam evaluates your skills in listening, reading,
              writing, and speaking. Whether you're taking the Academic or
              General Training version, our coaching covers all aspects,
              including:
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-4">
              <li>
                Listening: Techniques to understand spoken English in various
                accents and contexts.
              </li>
              <li>
                Reading: Practice with a variety of texts to improve
                comprehension and time management.
              </li>
              <li>
                Writing: Guidance on structuring essays and reports with clear,
                coherent arguments.
              </li>
              <li>
                Speaking: One-on-one speaking practice to build confidence and
                fluency.
              </li>
            </ul>
          </div>

          {/* PTE Coaching Program */}
          <div className="bg-white  p-4 md:p-8 rounded-lg shadow-lg">
            <h6 className="text-xl md:text-2xl font-semibold text-sky-500 mb-4">
              Our PTE Coaching Program
            </h6>
            <p className="text-gray-700 mb-4">
              PTE is a computer-based English test that evaluates your skills in
              listening, reading, speaking, and writing. Our PTE coaching
              program focuses on:
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-4">
              <li>
                Speaking and Writing: Learn how to respond to integrated tasks
                and improve your fluency and written expression.
              </li>
              <li>
                Reading: Strengthen your skills in handling a range of academic
                texts.
              </li>
              <li>
                Listening: Practice various question types that assess your
                ability to understand spoken English in diverse situations.
              </li>
              <li>
                Time Management: Develop strategies to manage your time
                effectively during the test, especially with PTE’s fast-paced
                format.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Helmet>
        <meta charSet="utf-8" />
        <title>IELTS & PTE Coaching in Chandigarh | My Visa Point</title>
        <meta
          name="description"
          content=" IELTS and PTE exams with expert coaching at My Visa Point, Chandigarh. Join us for tailored classes and achieve your study abroad goals today!
"
        />
        <link rel="canonical" href="https://myvisapoint.com/IeltsPte" />
      </Helmet>
    </section>
  );
};

export default IeltsPte;
